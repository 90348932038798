<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Lucrari">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Lucrari</b-breadcrumb-item>
          <b-breadcrumb-item active v-bind:text="'Detalii - Lucrare #' + getCode(work.code)"></b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <!-- Full Table -->
      <base-block title="Detalii" v-bind:subtitle="'Lucrare #'+getCode(work.code)">
        <b-row class="row-deck">
          <b-col md="5" class="mb-2">
            <b-button size="md" type="submit" variant="primary" @click="backToList">
              <small><i class="fa fa-fw fa-arrow-left"></i> Inapoi la lista de lucrari</small>
            </b-button>
          </b-col>
          <b-col md="4" class="mb-2">
            <span class="font-weight-bold font-size-lg text-uppercase" v-bind:class="'text-'+work.statusStyle">
              {{ work.statusLabel }}
            </span>
          </b-col>
          <b-col md="3" class="mb-2 text-right">
            <div class="d-none d-sm-block float-right w-100">
              <b-button size="md" type="submit" class="mr-4" variant="success" @click="printServicePaper">
                <small><i class="fa fa-fw fa-print mr-1"></i> Tipareste</small>
              </b-button>
              <router-link class="align-items-center btn btn-primary"  v-bind:to="getEditUrl(work.id)">
                <small><i class="fa fa-fw fa-pencil-alt"></i> Editare</small>
              </router-link>
            </div>
            <div class="d-block d-md-none">
              <b-button size="md" type="submit" class="mr-4" variant="success" @click="printServicePaper">
                <small><i class="fa fa-fw fa-print mr-1"></i> Tipareste</small>
              </b-button>
              <router-link class="align-items-center btn btn-primary"  v-bind:to="getEditUrl(work.id)">
                <small><i class="fa fa-fw fa-pencil-alt"></i> Editare</small>
              </router-link>
            </div>
          </b-col>
        </b-row>
        <hr/>
        <b-row class="row-deck" v-if="!dataLoad">
          <b-col md="3">
            <base-block title="Nume client" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-weight-bold text-primary font-size-sm">
                {{ work.clientName }}
              </p>
            </base-block>
          </b-col>
          <b-col md="2">
            <base-block title="Telefon" header-class="bg-gray" class="table-bordered" themed>
              <p class="text-dark font-weight-bold font-size-sm">
                {{ work.clientPhone }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Adresa de email" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-weight-bold text-dark font-size-sm">
                {{ work.clientEmail ? work.clientEmail : '-'  }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Adresa" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-weight-bold text-dark font-size-sm">
                {{ work.clientAddress ? work.clientAddress : '-'  }}
              </p>
            </base-block>
          </b-col>
          <b-col md="1">
            <base-block title="PIN" header-class="bg-gray" class="table-bordered" themed>
              <p class="text-dark font-weight-bold font-size-sm">
                {{ work.loginCode }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Echipament" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{ work.productName }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Serie echipament" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{ work.productSeries }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Termen de rezolvare" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{ work.resolveTermDate ? getDateStr(work.resolveTermDate) : '-' }}
              </p>
            </base-block>
          </b-col>
          <b-col md="3">
            <base-block title="Situatia produsului" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                <span v-if="work.warranty">Echipamentul <strong>ESTE</strong> in garantie</span>
                <span v-if="!work.warranty">Echipamentul <strong>NU</strong> este in garantie</span>
              </p>
            </base-block>
          </b-col>
          <b-col md="6">
            <base-block title="Descrierea produsului" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{work.productDescription}}
              </p>
            </base-block>
          </b-col>
          <b-col md="6">
            <base-block title="Descrierea problemei" header-class="bg-gray" class="table-bordered" themed>
              <p class="font-size-sm">
                {{work.problemDescription}}
              </p>
            </base-block>
          </b-col>
        </b-row>
        <base-block v-if="!dataLoad" title="Articole de deviz" header-class="bg-gray" class="table-bordered" themed>
          <template v-if="work.status === 5" #options>
            <button type="button" class="btn-block-option rounded bg-white text-success" @click="printEstimate">
              <i class="fa fa-print"></i> <span class="d-sm-none">Tipareste devizul</span><span class="d-none d-sm-inline">Tipareste devizul de lucrare</span>
            </button>
          </template>
          <b-table-simple responsive bordered striped table-class="table-vcenter">
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="d-sm-none" style="width: 80%;">Articol</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 10%;">Data</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 15%;">Tehnician</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 15%;">Actiune</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 20%;">Detalii</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 10%;">Unitate</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 10%;">Pret unitar</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 10%;">Pret</b-th>
                <b-th class="d-none d-sm-table-cell" style="width: 10%;">TVA</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-if="work.items.filter((item) => { return [2, 3, 4].includes(item.status) }).length <= 0 && !dataLoad">
                <b-td class="d-md-none text-center">
                  <b-alert variant="warning" show class="mt-3">
                    <p class="mb-0">
                      Nu am gasit nici un rezultat
                    </p>
                  </b-alert>
                </b-td>
                <b-td colspan="8" class="d-none d-sm-table-cell text-center">
                  <b-alert variant="warning" show class="mt-3">
                    <p class="mb-0">
                      Nu am gasit nici un rezultat
                    </p>
                  </b-alert>
                </b-td>
              </b-tr>
              <b-tr v-for="item in work.items.filter((item) => { return [2, 3, 4].includes(item.status) })" :key="item._id">
                <b-td class="d-sm-none" style="width: 15%;">
                  <div class="text-dark font-weight-bold">
                    {{ getDateTimeStr(item.createdAt) }}
                  </div>
                  <div class="text-primary font-weight-bold">
                    {{ item.technician.name }}
                  </div>
                  <div>{{ item.description }}</div>
                  <b-row>
                    <b-col class="col-6 mt-2">
                      <div><i class="fa fa-box"></i> <span>{{ item.priceModel.unity.unit }} <span>{{ item.priceModel.unity.unitLabel }}</span></span></div>
                    </b-col>
                    <b-col class="col-6 mt-2">
                      <div><i class="fa fa-money-bill"></i> <span>{{ item.priceModel.unitPrice.toFixed(2) }} <span>lei</span></span></div>
                    </b-col>
                    <b-col class="col-6 mt-2">
                      <div><i class="fa fa-dollar-sign"></i> <span>{{ item.priceModel.price.toFixed(2) }} <span>lei</span></span></div>
                    </b-col>
                    <b-col class="col-6 mt-2">
                      <div><i class="fa fa-percent"></i> <span class="text-warning">{{ item.priceModel.tax.toFixed(2) }} <span>lei</span></span></div>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm">
                  <span class="text-dark font-weight-light small">{{ getDateTimeStr(item.createdAt) }}</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm">
                  <span class="text-dark font-weight-bolder">{{ item.technician.name }}</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm">
                  <span class="text-dark font-weight-light">{{ item.actionLabel }}</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm">
                  <span class="text-dark font-weight-bold small">{{ item.description }}</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                  <span class="text-primary font-weight-bold">{{ item.priceModel.unity.unit }} ({{ item.priceModel.unity.unitLabel }})</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                  <span class="text-dark font-weight-bold">{{ item.priceModel.unitPrice.toFixed(2) }} lei</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                  <span class="text-primary font-weight-bold">{{ item.priceModel.price.toFixed(2) }} lei</span>
                </b-td>
                <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                  <span class="text-dark font-weight-bold">{{ item.priceModel.tax.toFixed(2) }} lei</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td class="d-sm-none" style="width: 15%;">
                  <b-row>
                    <b-col class="col-12 font-weight-bold">Total:</b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-6 text-right text-primary font-weight-bold">
                      <i class="fa fa-dollar-sign"></i> <span>{{ work.priceModel.price.toFixed(2) }} <span>lei</span></span>
                    </b-col>
                    <b-col class="col-6 text-right text-dark font-weight-bold">
                      <i class="fa fa-percent"></i> <span class="text-warning">{{ work.priceModel.tax.toFixed(2) }} <span>lei</span></span>
                    </b-col>
                  </b-row>
                </b-td>
                <td colspan="6" class="d-none d-sm-table-cell text-right font-weight-bold text-dark">Total</td>
                <td class="d-none d-sm-table-cell text-center font-weight-bold text-primary">{{ work.priceModel.price.toFixed(2) }} lei</td>
                <td class="d-none d-sm-table-cell text-center font-weight-bold text-dark">{{ work.priceModel.tax.toFixed(2) }} lei</td>
              </b-tr>
              <b-tr>
                <b-td class="d-sm-none" style="width: 15%;">
                  <div class="text-primary font-weight-bold">Total de plata client:</div>
                  <div class="text-right text-primary font-weight-bold">{{work.priceModel.totalPrice.toFixed(2)}} lei</div>
                </b-td>
                <td colspan="6" class="d-none d-sm-table-cell text-right font-weight-bold text-primary">Total de plata client</td>
                <td colspan="2" class="d-none d-sm-table-cell text-center font-weight-bold text-primary">{{work.priceModel.totalPrice.toFixed(2)}} lei</td>
              </b-tr>
              <b-tr>
                <b-td class="d-sm-none" style="width: 15%;">
                  <div class="text-warning font-weight-bold">Total piese folosite cu tva:</div>
                  <div class="text-right text-warning font-weight-bold">{{work.priceModel.productOutcomes.toFixed(2)}} lei</div>
                </b-td>
                <td colspan="6" class="d-none d-sm-table-cell text-right font-weight-bold text-warning">Total piese folosite cu tva</td>
                <td colspan="2" class="d-none d-sm-table-cell text-center font-weight-bold text-warning">{{work.priceModel.productOutcomes.toFixed(2)}} lei</td>
              </b-tr>
              <b-tr>
                <b-td class="d-sm-none" style="width: 15%;">
                  <div class="text-success font-weight-bold">Total profit deviz:</div>
                  <div class="text-right text-success font-weight-bold">{{work.priceModel.profit.toFixed(2)}} lei</div>
                </b-td>
                <td colspan="6" class="d-none d-sm-table-cell text-right font-weight-bold text-success">Total profit deviz</td>
                <td colspan="2" class="d-none d-sm-table-cell text-center font-weight-bold text-success">{{work.priceModel.profit.toFixed(2)}} lei</td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </base-block>
        <base-block v-if="!dataLoad && 5 !== work.status" title="Adauga articol nou" header-class="bg-gray" class="table-bordered" themed>
          <b-form @submit.stop.prevent="onAddArticleSubmit">
            <div class="row mb-2">
              <div class="col-sm-2 mb-2">
                <b-form-select id="status" size="lg" class="form-control-alt" @change="showFields" :options="work.options.statusOptions" plain name="status" v-model="$v.addArticleForm.status.$model" :state="!$v.addArticleForm.status.$error && (!errors.addArticleForm.status) && null" aria-describedby="status-feedback" @blur="removeServerErrors('addArticleForm')" @input="removeServerErrors('addArticleForm')"></b-form-select>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.status.required" id="status-feedback">
                  Campul este obligatoriu!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="errors.addArticleForm.status" v-text="errors.addArticleForm.status" id="status-feedback"></b-form-invalid-feedback>
              </div>
              <div v-if="pagesForStatus.includes('technician') || isAdmin()" class="col-sm-2 mb-2">
                <b-form-select id="technician" size="md" class="form-control-alt" :options="work.options.companyTechnicians" plain name="technician" v-model="$v.addArticleForm.technician.$model" :state="!$v.addArticleForm.technician.$error && (!errors.addArticleForm.technician) && null" aria-describedby="technician-feedback" @blur="removeServerErrors('addArticleForm')" @input="removeServerErrors('addArticleForm')"></b-form-select>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.technician.required" id="technician-feedback">
                  Campul este obligatoriu!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="errors.addArticleForm.technician" v-text="errors.addArticleForm.technician" id="technician-feedback"></b-form-invalid-feedback>
              </div>
              <div v-if="pagesForStatus.includes('description')" class="col-sm-4 mb-2">
                <b-form-input type="text" rows="1" size="md" class="form-control-alt" id="description" name="description" :placeholder="getFieldLabel('description')" v-model="$v.addArticleForm.description.$model" :state="!$v.addArticleForm.description.$error && (!errors.addArticleForm.description) && null" aria-describedby="description-feedback" @blur="removeServerErrors('addArticleForm')" @input="removeServerErrors('addArticleForm')"></b-form-input>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.description.required" id="description-feedback">
                  Campul este obligatoriu!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.description.minLength" id="description-feedback">
                  Campul trebuie sa aiba minimum 3 caractere!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="errors.addArticleForm.description" v-text="errors.addArticleForm.description" id="description-feedback"></b-form-invalid-feedback>
              </div>
              <div v-if="pagesForStatus.includes('unity')" class="col-sm-1 mb-2">
                <b-form-input type="text" size="md" class="form-control-alt" id="unity" name="unity" :placeholder="getFieldLabel('unity')" v-model="$v.addArticleForm.unity.$model" :state="!$v.addArticleForm.unity.$error && (!errors.addArticleForm.unity) && null" aria-describedby="unity-feedback" @blur="removeServerErrors('addArticleForm')" @input="removeServerErrors('addArticleForm')"></b-form-input>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.unity.required" id="unity-feedback">
                  Campul este obligatoriu!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.unity.decimal" id="unity-feedback">
                  Valoarea campului trebuie sa fie numar!
                </b-form-invalid-feedback>
              </div>
              <div v-if="pagesForStatus.includes('price')" class="col-sm-1 mb-2">
                <b-form-input type="text" size="md" class="form-control-alt" id="price" name="price" :placeholder="getFieldLabel('price')" v-model="$v.addArticleForm.price.$model" :state="!$v.addArticleForm.price.$error && (!errors.addArticleForm.price) && null" aria-describedby="price-feedback" @blur="removeServerErrors('addArticleForm')" @input="removeServerErrors('addArticleForm')"></b-form-input>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.price.required" id="price-feedback">
                  Campul este obligatoriu!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.price.decimal" id="price-feedback">
                  Valoarea campului trebuie sa fie numar!
                </b-form-invalid-feedback>
              </div>
              <div v-if="pagesForStatus.includes('boughtPrice')" class="col-sm-2 mb-2">
                <b-form-input type="text" size="md" class="form-control-alt" id="boughtPrice" name="boughtPrice" :placeholder="getFieldLabel('boughtPrice')" v-model="$v.addArticleForm.boughtPrice.$model" :state="!$v.addArticleForm.boughtPrice.$error && (!errors.addArticleForm.boughtPrice) && null" aria-describedby="bought-price-feedback" @blur="removeServerErrors('addArticleForm')" @input="removeServerErrors('addArticleForm')"></b-form-input>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.boughtPrice.required" id="bought-price-feedback">
                  Campul este obligatoriu!
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.addArticleForm.boughtPrice.decimal" id="bought-price-feedback">
                  Valoarea campului trebuie sa fie numar!
                </b-form-invalid-feedback>
              </div>
              <div class="col-sm-2 mb-2">
                <b-button type="submit" variant="primary" block>
                  <i class="fa fa-fw fa-plus-circle mr-1"></i> Adauga articol
                </b-button>
              </div>
            </div>
          </b-form>
        </base-block>
      </base-block>
    </div>
  </div>
</template>

<script>
import {
  CREATE_SERVICE_WORK_ITEM, PRINT_SERVICE_ESTIMATE,
  PRINT_SERVICE_PAPER,
  SERVICE_WORK_DETAIL
} from "../../../constants/service-model-graphql";
import {getCode} from "../../../constants/helpers/service-helper";
import {backTo, getDateStr, getDateTimeStr} from "../../../constants/helpers/common-helper";
import {validationMixin} from "vuelidate";
import {minLength, required, decimal, requiredIf} from "vuelidate/lib/validators";
import {fleshErrorMessage, handleFormValidation} from "../../../error/server-error";

export default {
  name: "ServiceWorkDetail",
  mixins: [validationMixin],
  data() {
    return {
      workId: atob(this.$route.params.id),
      work: {},
      dataLoad: true,
      roles: [],
      pagesForStatus: [],
      fieldLabels: [],
      userId: null,
      addArticleForm: {
        status: null,
        description: null,
        price: null,
        boughtPrice: null,
        unity: null,
        technician: null,
      },
      errors: {
        addArticleForm: {},
      },
    }
  },
  validations: {
    addArticleForm: {
      status: {
        required
      },
      description: {
        required: requiredIf(function () {
          return this.pagesForStatus.includes('description');
        }),
        minLength: minLength(3)
      },
      price: {
        required: requiredIf(function () {
          return this.pagesForStatus.includes('price');
        }),
        decimal
      },
      boughtPrice: {
        required: requiredIf(function () {
          return this.pagesForStatus.includes('boughtPrice');
        }),
        decimal
      },
      technician: {
        required: requiredIf(function () {
          return !this.isAdmin() && this.pagesForStatus.includes('technician');
        }),
        minLength: minLength(3)
      },
      unity: {
        required: requiredIf(function () {
          return this.pagesForStatus.includes('unity');
        }),
        decimal
      },
    }
  },
  methods: {
    getCode: getCode,
    backToList() {
      backTo(this.$router, '/service/list')
    },
    getEditUrl(id) {
      return '/service/edit/'+btoa(encodeURI(id));
    },
    getDateStr: getDateStr,
    getDateTimeStr: getDateTimeStr,
    removeServerErrors(form) {
      this.errors[form] = {};
    },
    onAddArticleSubmit() {
      this.errors.addArticleForm = {};
      this.$v.addArticleForm.$touch()
      if (this.$v.addArticleForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: CREATE_SERVICE_WORK_ITEM,
        variables: this.getMutationParams(this.$v.addArticleForm)
      }).then(() => {
        this.flashMessage.info({message: "Articol de deviz adaugat cu succes!"});
        this.$v.addArticleForm.status.$model = null;
        this.resetForm();
        this.pagesForStatus = [];
        this.fieldLabels = [];
        this.loadData();
      }).catch((error) => {
        let form = this.$v.addArticleForm;
        this.errors.addArticleForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    loadData() {
      this.dataLoad = true;
      this.$apollo.query({
        query: SERVICE_WORK_DETAIL,
        variables: {
            id: this.workId
        },
        fetchPolicy: "no-cache",
      }).then((result) => {
        this.roles = result.data.meUser.userRole.roles;
        this.userId = result.data.meUser._id;
        this.work = result.data.service;
        this.dataLoad = false;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    printServicePaper() {
      this.$apollo.mutate({
        mutation: PRINT_SERVICE_PAPER,
        variables: {
          'id': this.work._id
        }
      }).then((response) => {
        this.$print(response.data.createPrintServicePaper.printServicePaper.response);
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    printEstimate() {
      this.$apollo.mutate({
        mutation: PRINT_SERVICE_ESTIMATE,
        variables: {
          'id': this.work._id
        }
      }).then((response) => {
        this.$print(response.data.createPrintServiceEstimate.printServiceEstimate.response);
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    isAdmin() {
      return this.roles.includes('ROLE_SUPER_ADMIN') || this.roles.includes('ROLE_COMPANY_ADMIN');
    },
    showFields(value) {
      this.resetForm();
      if (!value) {
        this.pagesForStatus = [];
        this.fieldLabels = [];

        return;
      }
      if(!this.work.options.formFields[value]) {
        this.pagesForStatus = [];
        this.fieldLabels = [];

        return;
      }
      this.pagesForStatus = this.work.options.formFields[value];
      this.fieldLabels = this.work.options.formFieldLabels[value];
    },
    getMutationParams(form) {
      let params = {
        'serviceId': this.work._id,
        'status': form.status.$model,
        'technician': this.isAdmin() ? form.technician.$model: this.userId
      };
      let priceExists = false;
      this.pagesForStatus.forEach(function (property) {
        if (property.indexOf('price') >= 0 || property.indexOf('Price') >= 0) {
          priceExists = true;
          params[property] = parseFloat(form[property].$model);

          return;
        }
        if (property === 'unity') {
          params[property] = parseInt(form[property].$model);

          return;
        }
        params[property] = form[property].$model;
      });
      if (priceExists && !this.pagesForStatus.includes('unity')) {
        params['unity'] = 1;
      }

      return params;
    },
    getFieldLabel(field) {
      return this.fieldLabels[field] ?? field;
    },
    resetForm() {
      this.$v.addArticleForm.technician.$model = null;
      this.$v.addArticleForm.description.$model = null;
      this.$v.addArticleForm.price.$model = null;
      this.$v.addArticleForm.boughtPrice.$model = null;
      this.$v.addArticleForm.unity.$model = null;
      this.$v.addArticleForm.$reset();
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>